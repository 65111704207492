import * as React from "react";
import {
    Typography,
    Box,
    IconButton,
    Grid,
} from "@mui/material";
import { connect } from "react-redux";
import {
    useNavigate,
} from "react-router-dom";
import {
    centered_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Image, Table } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getProducts } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline, Error } from "@mui/icons-material";
import NewCard from "../app/components/NewCard";
import Ellipse from "../app/components/Ellipse";
import { AiOutlineRise, AiOutlineFall } from "react-icons/ai";

export const Products = ({
    getProducts,
    user,
    products,
    topProducts,
    leastProducts,
    isLoading
}) => {
    const navigate = useNavigate();
    const [error, setError] = React.useState(null);
    const [terms, setTerms] = React.useState(false);
    const [lowestStocks, setLowestStocks] = React.useState([]);

    useEffect(() => {
        getProducts({ page: 1, limit: 999999 })
    }, [])

    useEffect(() => {
        if (products?.docs) {
            const nonZeroStocks = products?.docs?.filter((product) => product.stocks?.reduce((a, b) => a + b.amount, 0) !== 0)
            const sortedStocks = nonZeroStocks?.sort((a, b) => a.stocks?.reduce((a, b) => a + b.amount, 0) - b.stocks?.reduce((a, b) => a + b.amount, 0))
            const lowestStocks = sortedStocks.slice(0, 5).map((product) => { return { sku: product.sku || product.externalId, name: product.name, amount: product.stocks?.reduce((a, b) => a + b.amount, 0) } })
            console.log(lowestStocks)
            setLowestStocks(lowestStocks)
        }
    }, [products])

    const columns = [
        {
            title: 'SKU',
            dataIndex: 'externalId',
            filters: [
                ...new Map(
                    products?.docs
                        ?.map((product) => [
                            product.sku || product.externalId, // Key to ensure uniqueness
                            { text: product.sku || product.externalId, value: product.sku || product.externalId },
                        ])
                ).values(),
            ],
            filterSearch: true,
            onFilter: (value, record) => record.externalId?.startsWith(value),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            filters: products?.docs?.map((product) => {
                return {
                    text: product.name,
                    value: product.name
                }
            }),
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: 'Size',
            dataIndex: 'size',
            filters: [
                ...new Map(
                    products?.docs
                        ?.filter((product) => { return product.size }).map((product) => [
                            product.size, // Key to ensure uniqueness
                            { text: product.size, value: product.size },
                        ])
                ).values(),
            ],
            filterSearch: true,
            onFilter: (value, record) => record.size?.startsWith(value),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            sorter: (a, b) => a.price - b.price,
            render: (_, { price }) => `${price} EGP`
        },
        {
            title: 'In Transfer',
            dataIndex: 'inbound',
            sorter: (a, b) => a.inbound - b.inbound,
        },
        {
            title: 'QTY on Hand',
            dataIndex: 'amount',
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Faulty',
            dataIndex: 'returns',
            sorter: (a, b) => a.returns - b.returns,
        },
    ];

    const data = products?.docs?.map((row) => {
        return {
            _id: row._id,
            externalId: row.sku || row.externalId,
            image: row.image,
            name: row.name,
            size: row.size,
            color: row.color,
            weight: row.weight,
            price: row.price,
            inbound: row.inbounds?.reduce((a, b) => a + (b.quantity - b.quantityReceived), 0),
            amount: row.stocks?.reduce((a, b) => a + b.amount, 0),
            returns: row.returns?.reduce((a, b) => a + b.amount, 0),
        }
    })

    return (
        <div>
            <Box sx={{ width: "100%", paddingX: 5 }}>
                <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1 }}>
                    <Grid item xs={4}>
                        <NewCard sx={{height: 300}} content={
                            <Box>
                                <Typography fontWeight={"bold"} marginBottom={2}>Total Pieces</Typography>
                                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                                    <Ellipse size={220} text={products?.docs?.reduce((acc, product) => acc + (product.stocks?.reduce((a, b) => a + (b.amount > 0 ? b.amount : 0), 0)), 0)} />
                                </Box>
                            </Box>
                        } />
                    </Grid>
                    <Grid item xs={4}>
                        <NewCard sx={{height: 300}} content={
                            <Box>
                                <Typography fontWeight={"bold"} marginBottom={2} >Stock Alert</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography fontSize={12} fontWeight={"bold"}>SKU</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography fontSize={12} fontWeight={"bold"}>Stock Level</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography fontSize={12} fontWeight={"bold"}>Zone</Typography>
                                    </Grid>
                                    {lowestStocks?.map((product) => {
                                        return (
                                            <>
                                                <Grid item xs={4}>
                                                    <Typography fontSize={12}>{product.sku}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography fontSize={12}>{product.amount}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography fontSize={12}><Error sx={{ marginInlineEnd: 1, width: 15, color: product.amount < 0 ? "red" : product.amount < 10 ? "orange" : "white" }} />{product.amount < 0 ? "Out Of Stock" : product.amount < 10 ? "Critical" : "Cautious"}</Typography>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        } />
                    </Grid>
                    <Grid item xs={2}>
                        <NewCard sx={{height: 300}} content={
                            <Box sx={{height: "100%"}}>
                                <Typography fontWeight={"bold"} marginBottom={2} >Top Performance</Typography>
                                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-evenly", height: "100%"}}>
                                    {topProducts?.map((product) => {
                                        return (
                                            <>
                                                <Grid item xs={2}>
                                                    <AiOutlineRise color="var(--primaryColor)"/>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography fontSize={16}>{product.sku}</Typography>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        } />
                    </Grid>
                    <Grid item xs={2}>
                        <NewCard sx={{height: 300}} content={
                            <Box sx={{height: "100%"}}>
                                <Typography fontWeight={"bold"} marginBottom={2} >Low Performance</Typography>
                                <Grid container spacing={2} sx={{ display: "flex", justifyContent: "space-evenly", height: "100%"}}>
                                    {leastProducts?.map((product) => {
                                        return (
                                            <>
                                                <Grid item xs={2}>
                                                    <AiOutlineFall color="var(--primaryColor)" />
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <Typography fontSize={16}>{product.sku}</Typography>
                                                </Grid>
                                            </>
                                        )
                                    })}
                                </Grid>
                            </Box>
                        } />
                    </Grid>
                </Grid>
                <Table
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    showSorterTooltip={{
                        target: 'sorter-icon',
                    }}
                />
            </Box>
        </div >
    );
};

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    token: state?.auth?.token,
    products: state?.records?.products,
    topProducts: state?.records?.topProducts,
    leastProducts: state?.records?.leastProducts,
});

const mapDispatchToProps = { getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(Products);
