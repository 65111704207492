import React from "react";

const Ellipse = ({ text, size = 150, stroke = 15 }) => {
    return (
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="gradient" x1="50%" y1="100%" x2="0%" y2="0%">
                    <stop offset="0%" stopColor="rgb(37,37,37)" />
                    <stop offset="76%" stopColor="rgb(247,70,60)" />
                </linearGradient>
            </defs>
            <circle
                cx={size / 2}
                cy={size / 2}
                r={size / 2 - 10}
                fill="none"
                stroke="url(#gradient)"
                strokeWidth={stroke}
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                fontSize="40"
                fill="#ffffff"
                fontWeight="bold"
            >
                {text}
            </text>
        </svg>
    );
};

export default Ellipse;
