import * as React from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Card,
    CardContent,
    IconButton
} from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sec_button } from "./Styles";
import { PushPin, PushPinOutlined } from "@mui/icons-material";

function NewCard({ title, content, sx }) {

    const [hovered, setHovered] = React.useState(false)

    return (
        <Card onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} sx={{ borderRadius: 5, backgroundColor: hovered ? "var(--appBg)" : "var(--secColor2)", ...sx }}>
            <CardContent sx={{height: "100%"}}>
                {content}
            </CardContent>
        </Card>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewCard);
