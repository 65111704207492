import * as React from 'react';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Typography, Box, Container, TextField, CssBaseline, Button, Avatar, Select, MenuItem, FormHelperText, InputLabel, CircularProgress, FormControl, Tabs, Tab, Card, CardHeader, CardMedia, IconButton, Autocomplete, Tooltip } from '@mui/material';
import { connect } from "react-redux";
import { Navigate, NavLink, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { centered_flex_box, MainInput, MainInputLabel, MainPassword, main_button, StyledInput, MainPhone, sec_button, left_flex_box } from '../app/components/Styles';
import { isMobile } from 'react-device-detect';
import { addInbounds, getProducts } from '../app/store/actions/dataActions';
import { Close, Delete, Download, Upload } from '@mui/icons-material';
import { useEffect } from 'react';
import Papa, { parse } from "papaparse";
import ReactFileReader from 'react-file-reader';
import { notification } from 'antd';
import download from 'downloadjs';

export const CreateInbound = ({ addInbounds, getProducts, products, user, isLoading }) => {

    const navigate = useNavigate();
    const [stockItems, setStockItems] = React.useState([]);
    const [file, setFile] = React.useState(null);

    const handleFiles = (files) => {
        setFile(files[0]);
    };

    useEffect(() => {
        if (file) {
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = async () => {
                try {
                    const parsed = Papa.parse(reader.result, { header: true });
                    let data = parsed.data;
                    console.log(data);
                    let items = [];
                    for (let i = 0; i < data.length; i++) {
                        let item = data[i];
                        let found = products.find(product => product.sku === item.SKU || product.externalId === item.SKU);
                        if (found) {
                            items.push({
                                _id: found._id,
                                name: found.name,
                                quantity: item.Quantity,
                            });
                        }
                    }
                    setStockItems(items);
                    console.log(items);
                } catch (err) {
                    console.log(err);
                }
            };
        }
    }, [file]);

    const generateCSVwithProducts = () => {
        //Generate CSV with product SKU, Name and amount 0
        let csv = "SKU,Name,Quantity\n";
        products.forEach(product => {
            csv += `${product.sku || product.externalId},${product.name},0\n`;
        });
        download(csv, "inbound.csv", "text/csv");
    }


    const handleSubmit = async (event) => {
        event.preventDefault();
        var order = {
            products: stockItems
        }
        addInbounds(order, navigate);
    };

    const addItem = (value) => {
        if (value) {
            setStockItems(prevItems => {
                let found = prevItems.find(item => item.stockItem === value._id);
                if (!found) {
                    return [
                        ...prevItems,
                        {
                            _id: value._id,
                            name: value.name,
                            quantity: 1,
                        }
                    ];
                }
                return prevItems;
            });
        }
    };

    const editItem = (idx, { target }) => {
        setStockItems(prevItems => {
            let newItems = [...prevItems];
            newItems[idx].quantity = target.value;
            return newItems;
        });
    };

    const removeItem = (id) => {
        let currItems = stockItems.filter((item) => item._id !== id)
        setStockItems(currItems)
    }

    useEffect(() => {
        getProducts({ page: 1, limit: 999999 })
    }, [])

    if (isLoading) {
        return (
            <Container maxWidth="xs">
                <Box sx={{ ...centered_flex_box, minHeight: isMobile ? "100%" : "100vh", minWidth: "100%" }}>
                    <CircularProgress sx={{ color: "var(--secColor4)" }} />
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography my={2} variant='h4' textAlign={"center"} fontWeight={"bold"} sx={{ color: "var(--secColor4)" }}>Send Inventory</Typography>
                    <Box>
                        <Typography component="h1" variant="h5">
                            Add Via CSV file
                        </Typography>
                        <Tooltip title="Download a sample CSV file with all products">
                            <Button
                                fullWidth
                                sx={{ my: 1, ...main_button }}
                                onClick={generateCSVwithProducts}
                            >
                                <Download /> CSV Sample
                            </Button>
                        </Tooltip>
                        <hr />
                        <Box
                            minWidth="100%"
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 1 }}
                        >
                            <Box minWidth="100%">
                                <ReactFileReader handleFiles={handleFiles} fileTypes={".csv"}>
                                    <Button fullWidth sx={{ my: 1, ...main_button }}>
                                        <Upload /> Upload .csv{" "}
                                    </Button>
                                </ReactFileReader>
                            </Box>
                        </Box>
                    </Box>
                    <hr />
                    <Typography component="h1" variant="h5">
                        Or Add Manually
                    </Typography>
                    <hr />
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        {products && <Autocomplete
                            options={products}
                            onChange={(_, value) => addItem(value)}
                            getOptionLabel={(product) => { return `(${product.sku}) ${product.name}` }}
                            renderInput={(params) => <MainInput
                                {...params}
                                margin="normal"
                                fullWidth
                                label="Add Products"
                            />}
                        />}
                        <hr />
                        <Grid container spacing={2}>
                            {stockItems.map((product, idx) => {
                                return (
                                    <>
                                        <Grid item xs={8} sx={{ ...left_flex_box }}>
                                            <Typography>{product.name} {product.size && `(${product.size})`} {product.color && `(${product.color})`} {product.weight && `(${product.weight})`}</Typography>
                                            {/* <Typography>{product.externalId}</Typography> */}
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MainInput
                                                margin="normal"
                                                required
                                                fullWidth
                                                label="Quantity"
                                                type="number"
                                                inputProps={{ min: 1 }}
                                                value={stockItems[idx].quantity}
                                                onChange={(event) => editItem(idx, event)}
                                                autoFocus
                                            />
                                        </Grid>
                                        <Grid item xs={1} sx={{ ...centered_flex_box }}>
                                            <IconButton onClick={() => removeItem(product._id)}><Delete sx={{ color: "var(--primaryColor)" }} /></IconButton>
                                        </Grid>
                                    </>
                                )
                            })}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, ...main_button }}
                        >
                            Send Inventory
                        </Button>
                    </Box>
                </Box>
            </Box >
        </Container >
    );
}

const mapStateToProps = (state) => ({
    user: state?.auth?.user,
    isLoading: state?.records?.isLoading || state?.wait?.isLoading,
    warehouses: state?.records?.warehouses?.docs,
    products: state?.records?.products?.docs
});

const mapDispatchToProps = { addInbounds, getProducts };

export default connect(mapStateToProps, mapDispatchToProps)(CreateInbound);