import * as React from "react";
import {
  Typography,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import { connect } from "react-redux";
import {
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  centered_flex_box,
} from "../app/components/Styles";
import { isMobile } from "react-device-detect";
import { Button, Image, Space, Table, Tag } from "antd";
import Logo from "../app/components/logo.png";
import PropTypes from "prop-types";
import MuiPhoneNumber from "material-ui-phone-number";
import ImageComponent from "../app/components/ImageComponent";
import { getExchanges, getOrders } from "../app/store/actions/dataActions";
import { useEffect } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import moment from "moment";
import NewCard from "../app/components/NewCard";
import Ellipse from "../app/components/Ellipse";
import { BarChart } from "@mui/x-charts";

export const Exchanges = ({
  getExchanges,
  user,
  exchanges,
  exchangeAnalysis,
  growthPercentage,
  isLoading
}) => {
  const navigate = useNavigate();
  const [error, setError] = React.useState(null);
  const [terms, setTerms] = React.useState(false);

  useEffect(() => {
    getExchanges({ page: 1, limit: 999999 })
  }, [])

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Date Created',
      dataIndex: 'date',
      sorter: (a, b) => { return moment(a.date).isBefore(b.date) ? -1 : 1 },
    },
    {
      title: 'Date Fulfilled',
      dataIndex: 'dateFulfilled',
      sorter: (a, b) => { return moment(a.dateFulfilled).isBefore(b.dateFulfilled) ? -1 : 1 },
    },
    {
      title: 'Date Delivered',
      dataIndex: 'dateDelivered',
      sorter: (a, b) => { return moment(a.dateDelivered).isBefore(b.dateDelivered) ? -1 : 1 },
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
      filters: exchanges?.docs?.filter((exchange) => exchange.customer !== null).map((exchange) => {
        return {
          text: exchange.customer?.name,
          value: exchange.customer?.name
        }
      }),
      filterSearch: true,
      onFilter: (value, record) => record.customer.startsWith(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: [
        {
          text: "New",
          value: "New"
        },
        {
          text: "Preparing",
          value: "Preparing"
        },
        {
          text: "Prepared",
          value: "Prepared"
        },
        {
          text: "Out for Delivery",
          value: "Out for Delivery"
        },
        {
          text: "Delivered",
          value: "Delivered"
        },
        {
          text: "Returned",
          value: "Returned"
        },
        {
          text: "Cancelled",
          value: "Cancelled"
        },
        {
          text: "Sold Out",
          value: "Sold Out"
        },
        {
          text: "Invalid",
          value: "Invalid"
        }
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, { status }) => status.toUpperCase()
    },
    {
      title: 'Actions',
      render: (_, { _id, originalOrder, incomingOrder, outgoingOrder }) => <Space>
        <Button onClick={() => navigate(`/orders/${originalOrder._id}`)}>View Original Order</Button>
        <Button onClick={() => navigate(`/orders/${outgoingOrder._id}`)}>View Outgoing Order</Button>
        <Button onClick={() => navigate(`/orders/${incomingOrder._id}`)}>View Incoming Order</Button>
      </Space>
    },
  ];

  const data = exchanges?.docs?.map((row) => {
    return {
      _id: row._id,
      date: moment(row.createdAt).format("lll"),
      dateFulfilled: row.outgoingOrder.preparedAt ? moment(row.outgoingOrder.preparedAt).format("lll") : null,
      dateDelivered: row.outgoingOrder.deliveredAt ? moment(row.outgoingOrder.deliveredAt).format("lll") : null,
      status: row.outgoingOrder.status,
      customer: row.customer,
      name: row.customer.name,
    }
  })

  return (
    <div>
      <Box sx={{ width: "100%", paddingX: 5 }}>
        <Grid container spacing={2} sx={{ ...centered_flex_box, mb: 1 }}>
          <Grid item xs={6}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2}>Total Exchanges</Typography>
                <Box sx={{ ...centered_flex_box, flexDirection: "column" }}>
                  {/* <Gauge width={200} height={200} sx={{fontSize: 50}} value={orders?.total} valueMax={orders?.total} /> */}
                  <Ellipse size={250} stroke={15} text={exchanges?.total} />
                  <Typography color={"var(--primaryColor)"} fontSize={12}>{growthPercentage > 0 ? `+${growthPercentage}` : growthPercentage}% since last month</Typography>
                </Box>
              </Box>
            } />
          </Grid>
          <Grid item xs={6}>
            <NewCard content={
              <Box>
                <Typography fontWeight={"bold"} marginBottom={2} >Exchange Reasons</Typography>
                {exchangeAnalysis && <BarChart
                  grid={{ horizontal: true }}
                  colors={["#F7463C", "#f7453c71", "#C83229", "#FF4237"]}
                  xAxis={[{ scaleType: 'band', data: ["Size Issue", "Wrong Color", "Defective Items"] }]}
                  series={[{ data: [exchangeAnalysis?.totalSizeIssues, exchangeAnalysis?.totalWrongColor, exchangeAnalysis?.totalDefective] }]}
                  width={700}
                  height={265}
                />}
              </Box>
            } />
          </Grid>
        </Grid>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          showSorterTooltip={{
            target: 'sorter-icon',
          }}
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state?.auth?.user,
  isLoading: state?.records?.isLoading || state?.wait?.isLoading,
  token: state?.auth?.token,
  exchanges: state?.records?.exchanges,
  exchangeAnalysis: state?.records?.exchangeAnalysis,
  growthPercentage: state?.records?.growthPercentage
});

const mapDispatchToProps = { getExchanges };

export default connect(mapStateToProps, mapDispatchToProps)(Exchanges);
